
import React from 'react';

import '../css/Main.css';

function Name(props) {
        return (
            <div>
                <section id="maintitle">
                    <p id="studio">MATTHEW MARTNICK</p>
                    <p id="occupation"> - DEVELOPMENT / DESIGN / ART</p>
                </section>
            </div>
        )
    }

export default Name;