
import React from 'react';

import '../../css/GalleryComponent.css';
import '../../css/Common.css';


function Logos(props) {
	return (
		<article className="galleryText">
			<p className="subTitle">Logo & Brand Design</p>
			<p className="desc">
				During my Associate degree program, I had majored in Digital/Multimedia and Information Resources Design where I had studied in practice various facets of design with the Adobe Suite.
				While it was highly focused on UI/UX design, the program also covered graphic design for print growing my proficiency with InDesign, Photoshop, Dimension, and Illustrator.
				Since then, I've actively sought out freelance work designing logos and promotional materials for small businesses and individuals.

				
							<a href="https://www.behance.net/gallery/112646161/Mythril-Botanicals-Branding" target="_blank" className="sublink">
					>> See my latest project...
						</a>

			</p>
		</article>
	)
}

export default Logos;