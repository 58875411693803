
import React from 'react';
import ReactGA from 'react-ga';

import { createBrowserHistory } from 'history';

import '../css/Main.css';

import Name from './Name';


function Main(props) {

	return (
		<div>
			<Name></Name>
		</div>


	)
}

export default Main;